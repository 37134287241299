<template>
  <v-card class="pa-2">
    <v-data-iterator
      item-key="IdCliente"
      :items="value"
      :search="search"
      :footer-props="{
        showCurrentPage: true,
      }"
    >
      <template v-slot:header>
        <div class="d-flex align-center">
          <v-subheader>Deuda por canal</v-subheader>
          <v-spacer />
          <v-text-field
            dense
            hide-details
            filled
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          />
          <v-btn
            color="primary"
            icon
            class="ml-1"
            text
            @click.stop="$emit('exportar', value, 'deudaPorCanal')"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-divider />
        <v-alert v-if="alertInfo" class="ma-2" dense text type="info">
          <small>Impagados actuales + pagos aplazados</small>
        </v-alert>
      </template>

      <template v-slot:default="{ items }">
        <v-list dense>
          <v-list-item v-for="item in items" :key="item.CanalAgente">
            <v-list-item-content>
              <div class="d-flex align-center">
                {{ item.CanalAgente }}
                <v-chip x-small class="v-chip--active ml-2" color="secondary" outlined>
                  {{
                    parseFloat(
                      (item.deuda * 100) / totalImpagados
                    ).toFixed(2)
                  }}
                  %
                </v-chip>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu open-on-hover offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">{{ item.deuda }} €</div>
                </template>
                <v-card class="pa-2 d-flex flex-column" style="gap: 10px">
                  <div v-if="item.deudaImp != 0" class="d-flex align-center">
                    Impagado:
                    <v-chip
                      outlined
                      label
                      small
                      class="ml-2"
                      :color="getSituacionIcon('Impagado').color"
                    >{{ item.deudaImp }} €</v-chip>
                  </div>
                  <div v-if="item.deudaApl != 0" class="d-flex align-center">
                    Pendiente:
                    <v-chip
                      outlined
                      label
                      small
                      class="ml-2"
                      :color="getSituacionIcon('Pendiente').color"
                    >{{ item.deudaApl }} €</v-chip>
                  </div>
                  <div v-if="item.deudaFra != 0" class="d-flex align-center">
                    Fraccionado:
                    <v-chip
                      outlined
                      label
                      small
                      class="ml-2"
                      :color="getSituacionIcon('Fraccionado 2 Pagos').color"
                    >{{ item.deudaFra }} €</v-chip>
                  </div>
                  <div v-if="item.deudaRec != 0" class="d-flex align-center">
                    Recuperado:
                    <v-chip
                      outlined
                      label
                      small
                      class="ml-2"
                      :color="getSituacionIcon('Cobrado').color"
                    >{{ item.deudaRec }} €</v-chip>
                  </div>
                </v-card>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import { getSituacionIcon } from '@/utils/index.js'

export default {
  props: {
    value: { type: Array, optional: false },
    totalImpagados: { type: Number, optional: false },
    alertInfo: { type: Boolean, default: false }
  },
  data() {
    return {
      search: null,
    };
  },
  methods: {
    getSituacionIcon
  }
};
</script>

<style scoped>
.v-data-iterator >>> .v-data-footer__select {
  display: none !important;
}
.v-data-iterator >>> .v-data-footer__pagination {
  margin-left: auto !important;
}
</style>